import { useState } from "react";
import { EyeIcon } from "../icons/eyeIcon";
import { EyeOffIcon } from "../icons/eyeOffIcon";

export const PasswordInput = ({
  label,
  borderColor,
  placeholder,
  onChange,
  required = true,
  disabled,
}) => {
  const [type, SetType] = useState("password");
  return (
    <>
      <label className="block mb-1 text-gray-700">{label}</label>
      <div
        className={`relative rounded-lg ${
          borderColor && "ring-2 ring-red-500"
        }`}
      >
        <input
          className="p-3 block w-full rounded-md text-sm text-gray-600 border border-gray-400 hover:border-blue-900 focus:outline-none focus:border-2 focus:border-blue-900"
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          type={type}
          onChange={(e) => onChange(e.target.value)}
        />
        <div className="absolute top-0 right-0">
          <button
            className="flex w-10 h-10 justify-center items-center mr-2 focus:outline-none"
            type="button"
            onClick={() => {
              if (type === "password") SetType("text");
              else if (type === "text") SetType("password");
            }}
          >
            {type === "password" && <EyeIcon size="20" color="gray" />}
            {type === "text" && <EyeOffIcon size="20" color="gray" />}
          </button>
        </div>
      </div>
    </>
  );
};
