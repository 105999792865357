const AddressMap = () => {
  return (
    <div className="google-map-code">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d196281.88170768236!2d-104.99519417355907!3d39.76425475366271!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b80aa231f17cf%3A0x118ef4f8278a36d6!2sDenver%2C%20CO!5e0!3m2!1sen!2sus!4v1665947054269!5m2!1sen!2sus"
        width="100%"
        height="620"
        style={{ border: 0 }}
        title="iFrame1"
        aria-hidden="false"
      ></iframe>
    </div>
  );
};
export default AddressMap;
