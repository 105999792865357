export const HubAccount = ({ width, height }) => {
    return (

    <svg
        width={width}
        height={height}
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
    
            <path
                d="M21.0002 8.20007C24.4502 8.20007 27.3002 11 27.3002 14.5001C27.3002 18.0001 24.4503 20.8001 21.0002 20.8001C17.5501 20.8001 14.7002 17.9502 14.7002 14.5001C14.7002 11.05 17.5501 8.20007 21.0002 8.20007ZM21.0002 6.20007C16.4002 6.20007 12.7002 9.90012 12.7002 14.5001C12.7002 19.1 16.4002 22.8001 21.0002 22.8001C25.6002 22.8001 29.3002 19.0501 29.3002 14.5001C29.3002 9.95007 25.6002 6.20007 21.0002 6.20007Z"
                fill="#9B9B9B" />
            <path
                d="M33.8504 34.2C33.0504 27.7 27.5004 22.85 21.0004 22.85C14.6004 22.85 9.10039 27.6 8.15039 33.95L6.15039 33.6501C7.25031 26.35 13.6004 20.8 21.0004 20.8C28.5504 20.8 34.9504 26.45 35.8504 33.9001L33.8504 34.2Z"
                fill="#9B9B9B" />
            <path
                d="M20.9504 2.90002C30.9004 2.90002 39.0004 11 39.0004 20.95C39.0004 30.9 30.9004 39 20.9504 39C11.0004 39 2.90039 30.9 2.90039 20.95C2.90039 11 11.0004 2.90002 20.9504 2.90002ZM20.9504 0.900024C9.90039 0.900024 0.900391 9.90002 0.900391 20.95C0.900391 32 9.90039 41 20.9504 41C32.0004 41 41.0004 32 41.0004 20.95C41.0004 9.90002 32.0504 0.900024 20.9504 0.900024Z"
                fill="#9B9B9B" />
    </svg>
  );
};

