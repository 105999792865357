import { useEffect } from "react";
import ApexCharts from "apexcharts";
import { SelectInput } from "./SelectInput";

import series from "../data/graphSeries.json";

const locationGraphSeries = series[1];

var options = {
  series: [locationGraphSeries],
  chart: {
    type: "bar",
    height: 380,
    width: "100%",
  },
  xaxis: {
    type: "category",
    labels: {
      formatter: (val) => {
        return val;
      },
    },
    group: {
      style: {
        fontSize: "10px",
        fontWeight: 700,
      },
      groups: [{ title: "Days of week", cols: 7 }],
    },
  },
  title: {
    text: "Average Daily Port Traffic",
    align: "center",
    style: {
      fontSize: "15px",
      color: "#6D6D6D",
    },
  },
  tooltip: {
    x: {
      formatter: function (val) {
        return val;
      },
    },
  },
};

const selectInputOptions = [
  { label: "Last 24 Hours", value: "24" },
  { label: "Last 15 mins", value: "15" },
];

const locationEntrances = [
  { label: "Main Entrance", value: "Main Entrance" },
  { label: "BackDoor", value: "BackDoor" },
  { label: "Side Entrance", value: "Side Entrance" },
];

const PageGraph = () => {
  useEffect(() => {
    const LocationChart = new ApexCharts(
      document.querySelector("#chartL"),
      options
    );

    LocationChart.render();
  }, []);

  return (
    <div className="bg-white shadow-xl rounded-sm p-5 w-1/2 mt-8 ">
      <div className="flex items-center justify-around">
        <span className="text-2xl font-bold w-1/2">Location Traffic</span>
        <div className="">
          <SelectInput
            onSelect={() => {}}
            placeholder="Main Entrance"
            options={locationEntrances}
            hub
            defVal="Main Entrance"
            size={170}
          />
        </div>
        <div className="p-2">
          <SelectInput
            onSelect={() => {}}
            placeholder="Last 24 Hours"
            options={selectInputOptions}
            hub
            defVal="Last 24 Hour"
            size={140}
          />
        </div>
      </div>

      {/* Table  */}
      <div className="flex flex-col justify-end gap-y-0 mt-5 ml-10">
        <div className="grid grid-cols-3 gap-20">
          <div></div>
          <div className="underline text-lg">Inbound</div>
          <div className="underline text-lg">Outbound</div>
        </div>
        <div className="grid grid-cols-3 gap-20">
          <div className="row-header">Total Vehicle</div>
          <div className="text-lg">2,025</div>
          <div className="text-lg">1,895</div>
        </div>
        <div className="grid grid-cols-3 gap-20">
          <div className="row-header">Container</div>
          <div className="text-lg">2,054</div>
          <div className="text-lg">1,396</div>
        </div>
      </div>

      {/*Graph */}
      <div className="mx-20 mt-10">
        <div id="chartL" className="z-0"></div>
      </div>
    </div>
  );
};

export default PageGraph;
