import axios from "axios";

axios.defaults.headers.common["Authorization"] =
  localStorage.getItem("access-token");

// API base URL
export const API_BASE_URL = axios.create({
  baseURL: "https://08e0lj5p3b.execute-api.us-west-2.amazonaws.com/api/v1",
  
});
