import { createSlice } from "@reduxjs/toolkit";

export const pdfSlice = createSlice({
    name: "results",
    initialState: {
        "event-data": {
            "public-image-url": "-",
            "seen-date": "-",
            "seen-time": "-",
            "direction-type": "-",
            "location": "-",
            "hub": "-",
        },
        "container-data": {
            "number": "-",
            "size": "-",
            "type": "-",
            "hazardous": "-",
            "owner": "-",
        },
        "chassis-data": {
            "number": "-",
            "size": "-",
            "iep": "-",
            "scac": "-",
            "plate": "-",
            "state": "-",
        },
        "truck-data": {
            "owner": "-",
            "scac": "-",
            "plate": "-",
            "state": "-",
            "make-model": "-",
            "year": "-",
        },
    },
    reducers: {
        setEventData: (state, actions) => {
            state["event-data"] = actions.payload;
        },
        setContainerData: (state, actions) => {
            state["container-data"] = actions.payload;
        },
        setChassisData: (state, actions) => {
            state["chassis-data"] = actions.payload;
        },
        setTruckData: (state, actions) => {
            state["truck-data"] = actions.payload;
        },
        reset: (state) => {
            state["event-data"] = {
                "public-image-url": "-",
                "seen-date": "-",
                "seen-time": "-",
                "direction-type": "-",
                "location": "-",
                "hub": "-",
            };

            state["container-data"] = {
                "number": "-",
                "size": "-",
                "type": "-",
                "hazardous": "-",
                "owner": "-",
            };

            state["chassis-data"] = {
                "number": "-",
                "size": "-",
                "iep": "-",
                "scac": "-",
                "plate": "-",
                "state": "-",
            };

            state["truck-data"] = {
                "owner": "-",
                "scac": "-",
                "plate": "-",
                "state": "-",
                "make-model": "-",
                "year": "-",
            };
        },
    },
});

export const { setEventData, setContainerData, setChassisData, setTruckData, reset } = pdfSlice.actions;

export default pdfSlice.reducer;
