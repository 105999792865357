import axios from "axios";
import { toast } from "react-toastify";
import { AuthService } from "./auth.service";
import { API_BASE_URL } from "./baseUrl";

export const queryEvent = (query, callback) => {
    // Set params
    let PARAMS = {};
    PARAMS[`${query.queryType}-id`] = query.query;
    PARAMS["search-type"] = query.queryType;
    PARAMS["begin-date"] = query.beginDate;
    PARAMS["end-date"] = query.endDate;

    if (query) {
        API_BASE_URL.get("/event", {
            params: PARAMS,
            headers: {
                Authorization: AuthService.GetLocalStorage("access-token"),
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    callback(res.data?.Items);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }
};

export const validAccessToken = () => {
    const expiration = AuthService.GetLocalStorage("expiration-datetime-epoch");
    const current = new Date().getTime();
    return expiration < current;
}

export const SearchEquipmentDetails = (containerId = "", callback) => {
    if (containerId) {
        API_BASE_URL.get("/objects/container/" + containerId, {
            headers: {
                Authorization: AuthService.GetLocalStorage("access-token"),
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    callback(res.data?.Items);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    }
};

export const getContainerDetails = (containerId = "", callback) => {
    if (containerId) {
        API_BASE_URL.get("/objects/container/" + containerId, {
            headers: {
                Authorization: AuthService.GetLocalStorage("access-token"),
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    callback(res.data?.Items);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    }
};

export const getChassisDetails = (chassisId = "", callback) => {
    if (containerId) {
        API_BASE_URL.get("/objects/chassis/" + chassisId, {
            headers: {
                Authorization: AuthService.GetLocalStorage("access-token"),
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    callback(res.data?.Items);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    }
};

export const searchEventDetails = (eventId = "", callback) => {
    if (eventId) {
        API_BASE_URL.get("/event/" + eventId, {
            headers: {
                Authorization: AuthService.GetLocalStorage("access-token"),
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    callback(res.data);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    }
};

/**
 *Fetch container Details
 * @param callback Callback function
 */
export const getHomeRecord = (callback) => {
    axios
        .all([
            API_BASE_URL.get(
                "/latest/PAST6HOURS-MOTION-COUNT_UIPA-USA-UTAH-SLC-UNIONPACIFIC",
                {
                    headers: {
                        Authorization:
                            AuthService.GetLocalStorage("access-token"),
                    },
                }
            ),
            API_BASE_URL.get(
                "/latest/LATEST-CONTAINER-IMAGE_UIPA-USA-UTAH-SLC-UNIONPACIFIC",
                {
                    headers: {
                        Authorization:
                            AuthService.GetLocalStorage("access-token"),
                    },
                }
            ),
            API_BASE_URL.get(
                "/latest/AIRQUALITY-LATEST_UIPA-USA-UTAH-SLC-UNIONPACIFIC",
                {
                    headers: {
                        Authorization:
                            AuthService.GetLocalStorage("access-token"),
                    },
                }
            ),
        ])
        .then((res) => {
            if (res[0].status === 200) {
                const response = res.map((item) => item?.data);
                callback(response);
            }
        })
        .catch((err) => {
            toast.error(err.message);
            callback("error");
        });
};

export const getCountById = (counterId = "", callback) => {
    if (counterId === "") {
        return null;
    }

    axios.all([
        API_BASE_URL.get(`/stats/count/${counterId}`).then((res) => {
            if (res.status !== 200) {
                return null;
            }

            callback(res["data"]);
        }),
    ]);
};

export const getLatestByKeyId = (keyId = "", callback) => {
    if (keyId === "") {
        return null;
    }

    axios.all([
        API_BASE_URL.get(`/latest/${keyId}`).then((res) => {
            if (res.status !== 200) {
                return null;
            }

            callback(res["data"]);
        }),
    ]);
};

/**
 * Generate PDF Link
 * @param callback Callback function
 */
export const getPDF = (record, callback) => {
    if (record) {
        API_BASE_URL.post(
            "/pdf",
            { ...record },
            {
                headers: {
                    Authorization: AuthService.GetLocalStorage("access-token"),
                },
            }
        )
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    callback(res.data);
                }
            })
            .catch((err) => {
                callback("error");
                toast.error(err.message);
            });
    }
};

/**
 * Endpoint for sending Email
 */
export const sendEmail = (record, callback) => {
    if (record) {
        API_BASE_URL.post(
            "/email/proof",
            {
                ...record,
            },
            {
                headers: {
                    Authorization: AuthService.GetLocalStorage("access-token"),
                },
            }
        )
            .then((res) => {
                if (res.status === 200) {
                    callback(res.data);
                }
            })
            .catch((err) => {
                callback("error");
                toast.error(err.message);
            });
    }
};
