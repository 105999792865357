import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";

// Pages
import Signup from "./pages/signup";
import InnerPage from "./pages/in";
import Home from "./pages/in/home";
import Search from "./pages/in/search";
import SearchResult from "./pages/in/search-result";
import ProofDetails from "./pages/in/proof-details";
import Hub from "./pages/in/hub";
import Login from "./pages/login";

function App() {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="signup" element={<Signup />} />
      <Route path="*" element={<Signup />} />

      {/* Authenticated pages */}
      <Route path="/in" element={<InnerPage />}>
        <Route index element={<Home />} />
        <Route path="search" element={<Search />} />
        <Route path="search-result" element={<SearchResult />} />
        <Route path="proof-details" element={<ProofDetails />} />
        <Route path="hub" element={<Hub />} />
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  );
}

export default App;
