import { configureStore } from "@reduxjs/toolkit";

// Reducers
import searchReducer from "../reducer-slices/search.slice";
import resultReducer from "../reducer-slices/resultlist.slice";
import pdfReducer from "../reducer-slices/pdf.slice"

export default configureStore({
  reducer: {
    search: searchReducer,
    resultList: resultReducer,
    pdf: pdfReducer
  },
});