import { useEffect, useState } from "react";
import { Button } from "../../../components/Button";
import { Header } from "../../../components/Header";
import { getLocaleDatetimeString } from "../../../services/app.service";
import { getCountById, getHomeRecord, getLatestByKeyId } from "../../../services/in.service";

const Home = () => {
  const [motionCount, setMotionCount] = useState(0);
  const [record, setRecord] = useState();
  const [loading, setLoading] = useState(false);

  // THIS USE EFFECT IS FOR PREPROCCESSING THE PAGE
  useEffect(() => {

    // key_id = "PAST6HOURS-MOTION-COUNT_UIPA-USA-UTAH-SLC-UNIONPACIFIC"

    getLatestByKeyId("PAST6HOURS-MOTION-COUNT_UIPA-USA-UTAH-SLC-UNIONPACIFIC", (result) => {
      setMotionCount(result["value"])
      return
    })
  }, [motionCount])

  useEffect(() => {

    setLoading(true);
    getHomeRecord((rec) => {
      setRecord({
        ...record,
        total: rec[0].value,
        imgPath: rec[1].value["image-url"],
        ghc: rec[2].value,
      });
    });
    setLoading(false);
  }, [record]);

  return (
    <div className="pb-20">
      <div className="intro-x">
        <Header />
      </div>
      <div className="-mt-16">
        <label className="block mx-10 mt-6 text-center font-bold text-xl txt-title-2 intro-y">
          HUB ACTIVITY: UTAH INLAND PORT
        </label>

        {/* Top statistics */}
        <div className="flex justify-center font-bold pt-10 text-sm text-gray-600">
          {/* Containers Seen today */}
          <div className="flex items-center bg-white rounded-md shadow-lg border border-gray-400 p-2 mx-4 intro-y">
            <span className="mx-4 flex items-center">
              <img
                src="/img/containers.png"
                alt="Containers"
                className=" h-6"
              />
            </span>
            <span>
              <label className="block">Total Containers</label>
              <label className="block">Seen today</label>
            </span>
            <span className="mx-4">
              <label className="block text-lg text-blue-900">
                {motionCount || "-"}
              </label>
              {loading && (
                <div className="flex justify-center mt-1">
                  <span className="block w-2 h-2 bg-black rounded-full animate-ping" />
                </div>
              )}
            </span>
          </div>

          {/* Last seen container */}
          <div className="flex items-center bg-white rounded-md shadow-lg border border-gray-400 p-2 mx-4 intro-y">
            <span className="mx-4 flex items-center">
              <img src="/img/last-seen.png" alt="Last seen" className="h-5" />
            </span>
            <span>
              <label className="block">Last Seen</label>
              <label className="block">Container</label>
            </span>
            {!loading && record?.imgPath ? (
              <span className="mx-4">
                <img
                  src={record?.imgPath}
                  alt="container"
                  className="rounded-sm"
                  style={{ maxWidth: "100px" }}
                />
              </span>
            )
              : (
                <span className="mx4" style={{ width: "50px", textAlign: "center" }}>
                  -
                </span>
              )}
            {loading && (
              <div className="flex justify-center mt-1 mx-5">
                <span className="block w-2 h-2 bg-black rounded-full animate-ping" />
              </div>
            )}
          </div>

          {/* Last seen container */}
          <div className="flex items-center bg-white rounded-md shadow-lg border border-gray-400 p-2 mx-4 intro-y">
            <span className="mx-4 flex items-center">
              <img src="/img/ghc.png" alt="GHC" className="h-8" />
            </span>
            <span>
              <label className="block">GHG at</label>
              <label className="block">Location</label>
              <label className="block">Last Week</label>
            </span>
            {!loading && (
              <span className="mx-4 text-blue-900">
                <label>{record?.ghc || "-"}</label>
              </span>
            )}

            {loading && (
              <div className="flex justify-center mt-1 mx-5">
                <span className="block w-2 h-2 bg-black rounded-full animate-ping" />
              </div>
            )}
          </div>
        </div>

        <div className="rounded-md shadow-soft p-10 bg-white mt-6 intro-y">
          <label className="block font-bold text-center text-4xl txt-title intro-y">
            <span className="mx-2 border-b-8 border-yellow-500">Proof</span>{" "}
            We've Seen Your Equipment.
          </label>
          <div className="flex-col justify-center items-center rounded-md mx-2 bg-gray-50 mt-8 py-6 shadow-lg">
            <div className="flex justify-center items-center">
              {/* Saw this */}
              <div className="p-5 intro-y">
                <label className="block mb-2 text-xl text-gray-500">
                  Saw this.
                </label>
                <img
                  src="/img/container.png"
                  className="w-52 h-52 rounded-lg object-cover"
                  alt="Saw this"
                />
              </div>
              <span className="text-8xl text-gray-600 mx-6 intro-y">+</span>

              {/* Plus this */}
              <div className="p-5 intro-y">
                <label className="block mb-2 text-xl text-gray-500">
                  Plus this.
                </label>
                <img
                  src="/img/track.png"
                  className="w-52 h-52 rounded-lg object-cover"
                  alt="Plus this"
                />
              </div>

              <span className="text-8xl text-gray-600 mx-6 intro-y">=</span>
              {/*Gives you this and more */}
              <div className="p-5 intro-y">
                <label className="block text-xl text-gray-500 -mt-6">
                  Gives you
                </label>
                <label className="block mb-2 text-xl text-gray-500">
                  this and more...
                </label>
                <img
                  src="/img/proof.png"
                  className="w-52 h-52 rounded-lg object-cover"
                  alt="this and more"
                />
              </div>
            </div>

            <label className="flex justify-center items-center mt-4 text-gray-600 text-3xl intro-y">
              <span className="font-bold mr-2">Accurate data</span> and{" "}
              <span className="font-bold mx-2">image</span> to make your job
              <span className="border-b-8 border-yellow-500 ml-2 mt-2">
                easier!
              </span>
            </label>
          </div>

          <div className="flex justify-center mt-16 intro-y">
            <div className="w-90">
              <Button
                title="Search Equipment"
                bgColor="bg-green-600 hover:bg-green-700"
                onClick={() => {
                  window.location.href = "/in/search";
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
