import truckSvg from "../../../hub-assets/noun-truck-container-4738949-CA2E55 3.svg";
import ghcSvg from "../../../hub-assets/noun-sustainability-1136095-CA2E55 (1) 3.svg";
import shareSvg from "../../../hub-assets/share.svg";
import { useEffect } from "react";
import Header from "../../../components/Hub-Header";
import PortGraph from "../../../components/PortGraph";
import LocationGraph from "../../../components/LocationGraph";
import Map from "../../../components/GoogleMap";
import { Close } from "../../../svgs/Close";
import { SelectInput } from "../../../components/SelectInput";
import Image from "../../../hub-assets/last-seen.png";
import HeatFlowMap from "../../../hub-assets/heat-flow-map.png";

const locationEntrances = [
  { label: "Main Entrance", value: "Main Entrance" },
  { label: "BackDoor", value: "BackDoor" },
  { label: "Side Entrance", value: "Side Entrance" },
];

const ports = [
  { label: "UIPS", value: "UIPS" },
  { label: "DVBL", value: "DVBL" },
  { label: "KYC", value: "KYC" },
];

const Hub = () => {
  // const [entrances, setEntrances] = useState([]);
  // const [port, setPorts] = useState([]);
  // const [show, setShow] = useState(false);

  useEffect(() => {
    // setShow(true);
  }, []);

  return (
    <>
      <div className="flex h-screen text-[#6D6D6D] mt-12 font-body">
        <div className="flex flex-col flex-1 w-full">
          <Header />
          {/* contents */}
          <div className="mx-6 -mt-8 intro-y">
            <div className="flex gap-x-5 mt-12">
              {/* Graphs */}
              <PortGraph />
              <LocationGraph />
            </div>

            {/*Current Location status */}
            <div className="container shadow-lg bg-white mt-5 flex justify-between w-full h-fit items-center">
              <div className="flex flex-col space-y-16 h-full p-8 relative">
                <div className="flex items-center gap-x-10 -mt-2">
                  <span className="text-2xl font-bold text-[#6D6D6D]">
                    Current Location Status
                  </span>
                  <div className="w-1/3">
                    <SelectInput
                      // onSelect={setEntrances}
                      onSelect={() => {}}
                      placeholder="Main Entrance"
                      options={locationEntrances}
                      hub
                      defVal="Main Entrance"
                      size={180}
                    />
                  </div>
                </div>
                <div className="mt-5 space-y-4 ml-10 text-[25px]">
                  <p>Vehicle Count Last Hour: 134</p>
                  <div className="flex gap-x-3 items-center ml-7">
                    <img
                      src={"/img/ghc.png"}
                      alt=""
                      className="w-7 h-1/5 object-contain"
                    />
                    <p className="">GHG Last 24 Hours: 1,392 C02e MT</p>
                  </div>
                </div>
              </div>
              <div className="w-1/3 relative p-2 mr-5">
                <img src={Image} alt="" />
                <span className="absolute bottom-4 left-4 text-[20px] text-white">
                  Last image
                </span>
              </div>
            </div>

            {/* Nearby QuayChain port */}
            <div className="mt-8 container flex flex-col shadow-lg bg-white p-10 h-fit w-full">
              <div className="flex justify-between">
                <span className="text-2xl font-bold">
                  Nearby QuayChain Port
                </span>
                <div className="w-fit flex gap-x-2">
                  <SelectInput
                    // onSelect={setPorts}
                    onSelect={() => {}}
                    placeholder=""
                    options={ports}
                    hub
                    defVal="UIPS"
                    size={180}
                  />
                  <SelectInput
                    // onSelect={setPorts}
                    onSelect={() => {}}
                    placeholder="Last 24 Hours"
                    options={ports}
                    hub
                    defVal="Last  24-Hours"
                    size={180}
                  />
                </div>
              </div>
              <div className="container flex justify-between items-start mt-5">
                <div className="w-1/4 shadow-lg mr-3">
                  <div className="bg-primary-clr-2 p-4">
                    <div className="w-full flex justify-end pr-1 text-white">
                      <Close width="10" height="11" />
                    </div>
                    <div className="text-white space-y-5">
                      <span className="font-bold text-xl ml-4">UIPA, SLC</span>
                      <p className="text-center text-sm">
                        40.76005, -112.03608
                      </p>
                    </div>
                    <div className="flex justify-end">
                      <img src={shareSvg} alt="" />
                    </div>
                  </div>
                  <div className="bg-slate-100 p-5 h-[485px] flex flex-col justify-between shadow-lg w-full">
                    <div className="w-full">
                      <span className="text-[14px] text-black">
                        Aggregate Equipment Volume &<br /> Greenhouse Gas (GHG)
                        Emissions
                      </span>
                      <div className="flex flex-col gap-y-3 mt-10">
                        <div className="flex items-center px-2">
                          <span className="block mr-6">
                            <img src={truckSvg} alt="" className="w-[28px]" />
                          </span>
                          <span className="text-black text-[16px]">
                            <span className="font-bold">Total vehicle: </span>{" "}
                            925
                          </span>
                        </div>
                        <div className="flex items-center px-2">
                          <span className="block mr-6">
                            <img
                              src={ghcSvg}
                              alt=""
                              className="w-[28px] object-contain"
                            />
                          </span>
                          <span className="text-black text-[16px]">
                            <span className="font-bold">GHG*:</span> 389 C02e
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="text-[12px]">
                        *GHG in MT - expressed as CO2e MT
                      </span>
                    </div>
                  </div>
                </div>
                {/*End of UIPA, SLC */}
                <div className="w-3/4 shadow-lg">
                  <Map />
                </div>
              </div>
            </div>
            {/* Traffic heat flow map */}
            <div className="mt-8 container flex flex-col shadow-lg bg-white p-10 h-fit w-full mb-20">
              <div className="flex justify-between">
                <span className="text-2xl font-bold">
                  Traffic Heat Flow Map
                </span>
                <div className="flex gap-x-5">
                  <SelectInput
                    // onSelect={setPorts}
                    onSelect={() => {}}
                    placeholder="Last 24 Hours"
                    options={ports}
                    hub
                    defVal="UIPS Location 1"
                    size={170}
                  />
                </div>
              </div>
              <div className="w-full mt-6">
                <img src={HeatFlowMap} alt="heat flow map" />
                {/* <Map /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hub;
