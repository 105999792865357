import React, { useState, useEffect } from 'react';
import { Button } from './Button';

import { getPDF, sendEmail } from '../services/in.service';
import { toast } from 'react-toastify';

export const ProofModal = ({ setShowModal, PDFRecord, ReportId }) => {

  const [isSending, setIsSending] = useState(false);
  const [emailAddresses, setEmailAddresses] = useState("");
  const [sendSelfCopy, setSendSelfCopy] = useState(false);

  return (
    <div
      className="fixed w-full h-screen top-0 left-0"
      style={{ zIndex: 100, backgroundColor: "#111111dd" }}
    >
      <div className="flex flex-col justify-center items-center">
        <div className="bg-white absolute top-12 rounded-md px-14 py-10 shadow-2g" style={{
          width: window.innerWidth < 620 ? "90%" : 620
        }}>
          {/* Title */}
          <h1 className="text-3xl txt-title text-center font-semibold intro-y">
            <span className="mr-2 border-b-8 border-yellow-500">
              Share
            </span>{" "}
            Your Proof!
          </h1>

          {/* Is Sending */}
          {isSending && <div className='px-6 py-4 rounded-md mt-10 w-3/3 intro-y' style={styles.container}>
            Sending...
            <div className="bg-black w-5 h-5 rounded-full mr-4 animate-ping" style={{margin: "10px 25px"}} />          
            </div>
          }

          {!isSending && <div className="border border-gray-200 px-6 py-4 rounded-md mt-10 w-3/3 intro-y">
            <h3 className="font-semibold underline">
              Sharing Proof of Event for{" "}
              {""}
            </h3>
            <table className="mt-4 text-sm">
              <tbody>
                <tr className="block mt-3">
                  <td className="font-semibold w-32">Date/Time</td>
                  <td>
                    {PDFRecord["event-data"]["seen-date"] + " " + PDFRecord["event-data"]["seen-time"]}
                  </td>
                </tr>
                <tr className="block mt-3">
                  <td className="font-semibold w-32">Location</td>
                  <td>{PDFRecord["event-data"]["location-name"]}</td>
                </tr>
                <tr className="block mt-3">
                  <td className="font-semibold w-32">Report ID#</td>
                  <td className="text-blue-700">{ReportId}</td>
                </tr>
              </tbody>
            </table>
          </div>
          }

          {!isSending && <div>
            {/* Email Address Field */}
            <input className='intro-y' style={styles.textField} type={"text"} placeholder={"Up to 5 Email Addresses (comma separated)"} value={emailAddresses} onChange={(e) => setEmailAddresses(e.target.value)} />

            {/* Self Copy */}
            <div className="send-copy mt-1 mb-1 flex justify-start intro-x">
            <input type="checkbox" style={{ width: 20, height: 20, alignSelf: "center" }} checked={sendSelfCopy} onChange={e => setSendSelfCopy(e.target.checked)} />
            <label className='m-5'>Send me a copy</label>
            </div>

            {/* Message to recipient */}
            {/* <textarea style={styles.textArea} placeholder={"Enter Message to Recipient"} ></textarea> */}
          </div>}

          {/* footer */}
          {!isSending && <div style={styles.container}>
            <Button
              title='Cancel'
              style={{ ...styles.button, backgroundColor: "darkred" }}
              onClick={() => setShowModal(false)}
            />
            <Button
              title='Submit'
              style={{ ...styles.button, backgroundColor: "darkgreen" }}
              onClick={() => {
                if (emailAddresses === "" || emailAddresses === null){
                  toast.error("No email provided");
                  setShowModal(false);
                  return;
                }

                setIsSending(true);
                getPDF(PDFRecord, (pdfUrl) => {

                  // Create payload and send it

                    // In case a self copy needs to be made
                    const email = emailAddresses + ", " + String(localStorage.getItem("user-email"));

                    let payload = {
                      "email": sendSelfCopy ? email : emailAddresses,
                      "report-url": pdfUrl
                    }

                    sendEmail(payload, (_) => {
                      setIsSending(false);
                    });
                    toast.success("Message Sent!");
                    setShowModal(false);
                });
              }}
            />
          </div>
          }

          {/* End of white container */}
        </div>
      </div>
    </div>
  )

}


const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  button: {
    width: 100,
    margin: "5px 5px",
    zIndex: 1000
  },
  textField: {
    margin: "10px 0px",
    border: "1px solid #ddd",
    borderRadius: 5,
    width: "100%",
    padding: 5
  },
  textArea: {
    mathin: "10px 0px",
    width: "100%",
    height: 200,
    border: "1px solid #ddd",
    borderRadius: 5,
    resize: "none",
    padding: 5,
  }
}

export default ProofModal