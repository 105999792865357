import { useEffect, useState } from "react";

export const Button = ({
  style,
  bgColor,
  isLoading,
  title,
  type = "submit",
  onClick,
}) => {
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(isLoading || false);
  }, [isLoading]);

  return (
    <button
      type={type}
      className={`flex items-center justify-center uppercase text-gray-100 font-bold py-3 px-20 rounded-md w-full shadow-lg ${
        bgColor ? bgColor : "bg-primary-clr hover:bg-blue-900"
      }`}
      onClick={onClick && onClick}
      style={style}
    >
      {/* Loader */}
      {loading && (
        <div className="bg-white w-5 h-5 rounded-full mr-4 animate-ping" />
      )}

      <span className="">{title}</span>
    </button>
  );
};
