import { SearchInputPlain } from "./SearchInputPlain";
import { Settings } from "../svgs/Settings";
import { Faq } from "../svgs/Faq";
import { HubAccount } from "../svgs/HubAccount";

const Header = () => {
  return (
    <div className="container flex items-center justify-between px-7">
      <div className="text-3xl flex text-[#17396B]">
        Dashboard: PORT OF LONG BEACH
      </div>
      <div className="flex flex-row gap-x-16 items-center">
        <SearchInputPlain placeholder="Search" bgColor="#F9F9F9" hub />
        <div className="flex items-center gap-x-7">
          <div className="flex relative items-center rounded-full bg-gray-100 w-9 h-9 mr-10">
            <HubAccount height="41" width="41" />
            <div>
              <span className="absolute left-12 top-1 text-[20px] text-[#9B9B9B] cursor-pointer">
                Logout
              </span>
            </div>
          </div>
        </div>
        <div className="flex space-x-3 justify-between mr-9 cursor-pointer">
          <Faq width="30" height="30" />
          <Settings width="30" height="30" />
        </div>
      </div>
    </div>
  );
};

export default Header;
