import { Menu, Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import { CheveronDownIcon } from "../icons/chevron-down";

export const SelectInput = ({
  onSelect,
  label,
  placeholder,
  bgColor,
  options,
  required = true,
  defVal,
  hub,
  size,
}) => {
  const [active, SetActive] = useState({ label: "", value: defVal });

  useEffect(() => {
    onSelect(active);
  }, [active]);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left w-full">
        <div className={`inline-flex justify-center w-[${size}px]`}>
          <Menu.Button
            type="button"
            className={`relative w-full overflow-hidden cursor-pointer focus:outline-none`}
          >
            <label className="block text-left mb-1 text-gray-700">
              {label}
            </label>
            <input
              className={`block w-full rounded-md text-gray-600 border border-gray-400 hover:border-blue-900 focus:outline-none focus:border-2 focus:border-blue-900 shadow-soft ${
                hub
                  ? "px-3 py-2 text-[16px] bg-[#F9F9F9]"
                  : "p-3 text-sm bg-white"
              }`}
              placeholder={placeholder}
              required={required}
              value={active?.value}
              onChange={(e) => {
                e.preventDefault();
                return false;
              }}
              onKeyDown={(e) => {
                e.preventDefault();
                return false;
              }}
            />
            {/* Input overlay */}
            <div className="w-full h-full bg-transparent absolute top-0 left-0" />
            <span
              className={`absolute right-3 ${hub ? "bottom-2" : "bottom-3"}`}
            >
              <CheveronDownIcon />
            </span>
          </Menu.Button>
        </div>
        <div>
          <Transition
            as="div"
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="mt-1 origin-top-right absolute right-0 w-full rounded-2xl shadow-soft bg-white border border-gray-200 divide-y divide-gray-100 py-2 focus:outline-none">
              {options?.map((item, idx) => (
                <div className="border-b border-gray-100" key={idx}>
                  <Menu.Item>
                    <button
                      type="button"
                      className="text-gray-600 px-4 py-2 hover:bg-gray-50 w-full text-left focus:outline-none"
                      onClick={() => {
                        onSelect(item);
                        SetActive(item);
                      }}
                    >
                      {item?.label}
                    </button>
                  </Menu.Item>
                </div>
              ))}
            </Menu.Items>
          </Transition>
        </div>
      </Menu>
    </>
  );
};
