import sideNav from "../../data/sideNav.json";
import { Link, NavLink } from "react-router-dom";
import { AuthService } from "../../services/auth.service";
import { Star } from "../../svgs/Star";
import { LogoWhite } from "../../svgs/LogoWhite";

export const SideNav = () => {
  return (
    <aside className="z-30 flex-shrink-0 hidden w-64 overflow-y-auto bg-primary-clr-2 lg:block">
      <div className="py-4 px-6" style={{ height: "100%" }}>
        <div className="my-8">
          <Link to="/in">
            <LogoWhite width="180" />
          </Link>
        </div>

        {/* Nav link text container */}
        <div
          className="my-8"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "85%",
          }}
        >
          <div className="">
            <ul className="mt-6">
              {sideNav.map((nav, idx) => (
                <li
                  className="relative text-gray-300 px-6 mr-10 border-b border-gray-600 hover:text-white "
                  key={idx}
                >
                  <NavLink
                    to={nav.path}
                    className={({ isActive }) =>
                      isActive ? "active-link" : "def-link"
                    }
                  >
                    <div className="flex items-center w-48 -ml-4 py-3">
                      <span className="indicator -mt-1 mr-2">
                        <Star />
                      </span>
                      <span className="block text-sm">{nav?.name}</span>
                    </div>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="text-gray-300 ml-5">
            <ul>
              <li className="my-4 hover:text-white">
                <Link to="">About Us</Link>
              </li>
              <li className="my-4 hover:text-white">
                <Link to="">Contact Us</Link>
              </li>
              <li className="my-4 hover:text-white">
                <Link to="">Privacy Policy</Link>
              </li>
              <li className="mb-2 mt-8 text-red-500 hover:text-red-700">
                <button
                  onClick={() => {
                    const loggedOut =
                      AuthService.RemoveLocalStorageItem("access-token");
                    if (loggedOut) {
                      AuthService.SetLocalStorage("keep-logged-in", false);
                      window.location.href = "/";
                    }
                  }}
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </aside>
  );
};
