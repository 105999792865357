export function calcChecksum(containerNumber) {
  if (typeof containerNumber !== "string") return -3;
  containerNumber = containerNumber.replace(/\s/g, "");
  if (containerNumber.length !== 10) return -2; // wrong size
  containerNumber = containerNumber.toUpperCase();

  var equivalentNumericalValues = new Map();
  equivalentNumericalValues.set("0", 0);
  equivalentNumericalValues.set("1", 1);
  equivalentNumericalValues.set("2", 2);
  equivalentNumericalValues.set("3", 3);
  equivalentNumericalValues.set("4", 4);
  equivalentNumericalValues.set("5", 5);
  equivalentNumericalValues.set("6", 6);
  equivalentNumericalValues.set("7", 7);
  equivalentNumericalValues.set("8", 8);
  equivalentNumericalValues.set("9", 9);
  equivalentNumericalValues.set("A", 10);
  equivalentNumericalValues.set("B", 12);
  equivalentNumericalValues.set("C", 13);
  equivalentNumericalValues.set("D", 14);
  equivalentNumericalValues.set("E", 15);
  equivalentNumericalValues.set("F", 16);
  equivalentNumericalValues.set("G", 17);
  equivalentNumericalValues.set("H", 18);
  equivalentNumericalValues.set("I", 19);
  equivalentNumericalValues.set("J", 20);
  equivalentNumericalValues.set("K", 21);
  equivalentNumericalValues.set("L", 23);
  equivalentNumericalValues.set("M", 24);
  equivalentNumericalValues.set("N", 25);
  equivalentNumericalValues.set("O", 26);
  equivalentNumericalValues.set("P", 27);
  equivalentNumericalValues.set("Q", 28);
  equivalentNumericalValues.set("R", 29);
  equivalentNumericalValues.set("S", 30);
  equivalentNumericalValues.set("T", 31);
  equivalentNumericalValues.set("U", 32);
  equivalentNumericalValues.set("V", 34);
  equivalentNumericalValues.set("W", 35);
  equivalentNumericalValues.set("X", 36);
  equivalentNumericalValues.set("Y", 37);
  equivalentNumericalValues.set("Z", 38);

  var sum = 0;
  var i;

  // only 10 items
  for (i = 0; i < 10; i++) {
    sum +=
      equivalentNumericalValues.get(containerNumber.charAt(i)) * Math.pow(2, i);
  }

  var sumInteger = Math.floor(sum);
  //  alert(sumInteger)
  sumInteger = Math.floor(sumInteger / 11);
  //  alert(sumInteger)
  sumInteger *= 11;
  //alert(sumInteger)

  var last = Math.floor(sum) - sumInteger;
  //alert("output " + sum + "  " + sumInteger + "   " + last);

  return last % 10;
}
