import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setContainerData, setChassisData, setTruckData } from "../reducer-slices/pdf.slice";

const createRecord = (type, content) => {
    let record = null;
    switch (type) {
        case "Container":
            record = {
                "number": content["container-id"] || "-",
                "size": content["size"] || "-",
                "type": content["type"] || "-",
                "hazardous": content["hazardous"] || "-",
                "owner": content["owner"] || "-"
            }
            return record;
        case "Chassis":
            record = {
                "number": content["chassis-id"] || "-",
                "size": content["size"] || "-",
                "iep": content["iep"] || "-",
                "scac": content["scac"] || "-",
                "plate": content["plate"] || "-",
                "state": content["state"] || "-"
            }
            return record;
        case "Truck":
            record = {
                "owner": content["owner"] || "-",
                "scac": content["scac"] || "-",
                "plate": content["plate"] || "-",
                "state": content["state"] || "-",
                "make-model": content["make-model"] || "-",
                "year": content["year"] || "-"
            }
            return record
        default:
            return null
    }
}

const EquipmentTable = ({ EquipmentType, EquipmentId }) => {

    const pdfData = useSelector(state => state.pdf);
    const dispatch = useDispatch();

    const [equipment, setEquipment] = useState(null);

    const determinePath = (type) => {
        switch (type) {
            case "Container":
                return `/objects/container/${EquipmentId}`
            case "Chassis":
                return `/objects/chassis/${EquipmentId}`
            case "Truck":
                return `/objects/truck/${EquipmentId}`
            default:
                return null;
        }
    }

    const setPdfData = (type, content) => {
        if (!content) {
            return;
        }

        switch (type) {
            case "Container":
                dispatch(setContainerData(content));
                break;
            case "Chassis":
                dispatch(setChassisData(content))
                break;
            case "Truck":
                dispatch(setTruckData(content));
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        const abortController = new AbortController();

        const fetchDetails = async () => {
            const baseUrl = "https://1oc88j93je.execute-api.us-west-2.amazonaws.com";
            const path = determinePath(EquipmentType);

            await fetch(`${baseUrl}${path}`)
                .then(data => data.json())
                .then(json => {
                    setEquipment(createRecord(EquipmentType, json["Items"][0]));
                    setPdfData(EquipmentType, equipment);
                });
        }

        fetchDetails();

        return () => {
            abortController.abort();
        };
    }, [equipment]);

    const row = (key, value) => {
        return (
            <tr key={key}>
                <td className="w-32 text-sm"><b>{key || "N/A"}</b></td>
                <td style={{display: "flex", justifyContent: "center", alignItems:"center"}}>
                    {value || "-"}
                </td>
            </tr>
        )
    }

    return (
        <div className="shadow-lg rounded-md w-full border-2 border-gray-200 mr-4 mt-5 intro-y">
            <label className="block px-6 py-2 border-b border-gray-400">
                <h1><b>{EquipmentType.toUpperCase()}</b></h1>
            </label>
            {
                equipment && <table className="block px-6 pt-4 pb-8">
                    <tbody>
                        {Object.keys(equipment).map((k) => row(k.toUpperCase(), equipment[k]))}
                    </tbody>
                </table>
            }
            {
                !equipment && <table className='block px-6 pt-4 pb-8'>
                    <tbody>
                        {Object.keys(pdfData[EquipmentType.toLowerCase() + "-data"]).map((k) => row(k.toUpperCase(), pdfData[EquipmentType.toLowerCase() + "-data"][k]))}
                    </tbody>
                </table>
            }
        </div>
)}


export default EquipmentTable;