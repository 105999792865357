import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { SideNav } from "../../components/sidenav";
import { AuthService } from "../../services/auth.service";

// Landing page on successful authentication
const Index = () => {
  const [signedIn,setSignedIn]=useState(false);

  useEffect(() => {
    if (
      !AuthService.GetLocalStorage("access-token") &&
      AuthService.GetLocalStorage("keep-logged-in") !== "true"
    )
      window.location.href = "/";
      else
      setSignedIn(true)
  }, []);

  return (
    <>
    {signedIn && (
      <div className="flex h-screen">
        {/* Desktop Side navigation */}
        <SideNav />

        {/* Content area */}
        <div className="flex flex-col flex-1 w-full">
          <main className="h-full overflow-y-auto bg-gray-100 bg-gradient-to-br from-teal-50 via-gray-200 to-red-100">
            <div className="px-6 mx-auto">
              {/* Page content */}
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    )}
      
    </>
  );
};

export default Index;
