import { useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Header } from "../../../components/Header";
import { SearchEquipment } from "../../../components/SearchEquipment";
import { PlayIcon } from "../../../icons/playIcon";
import {
  convertQcDateStringToDatetimeObject,
  convertQcDateStringToISOString,
} from "../../../services/app.service";

// API endpoint to search a particular container
import { queryEvent } from "../../../services/in.service";

// Redux
import { useDispatch, useSelector } from "react-redux";

const SearchResult = () => {
  const [records, setRecords] = useState([]);

  const dispatch = useDispatch();
  const searchQuery = useSelector((state) => state.search);
  const resultList = useSelector((state) => state.resultList);

  // To show loading indicator on button
  const [loading, setLoading] = useState(false);

  const [sort, setSort] = useState({
    dateDescending: true,
    timeDescending: true,
  });

  const tableHeader = [
    "Date",
    "Time",
    "Container",
    "Chassis",
    "Truck",
    "Location",
    "Direction",
    "Hub",
    "Proof",
    "Details",
  ];

  // Sort items array by Date
  const sortDate = (sortNum) => {
    if (sortNum === 1)
      setRecords(
        records.sort(
          (x, y) =>
            +new Date(convertQcDateStringToISOString(x["event-first-dt-iso"])) -
            +new Date(convertQcDateStringToISOString(y["event-first-dt-iso"]))
        )
      );
    else if (sortNum === 2)
      setRecords(
        records.sort(
          (x, y) =>
            +new Date(convertQcDateStringToISOString(y["event-first-dt-iso"])) -
            +new Date(convertQcDateStringToISOString(x["event-first-dt-iso"]))
        )
      );
  };

  // Sort items arry by Time
  const sortTime = (sortNum) => {
    if (sortNum === 1)
      setRecords(
        records.sort(
          (x, y) =>
            +new Date(
              convertQcDateStringToISOString(x["event-first-dt-iso"])
            ).setFullYear(2022, 0, 1) -
            +new Date(
              convertQcDateStringToISOString(y["event-first-dt-iso"])
            ).setFullYear(2022, 0, 1)
        )
      );
    else if (sortNum === 2)
      setRecords(
        records.sort(
          (x, y) =>
            +new Date(
              convertQcDateStringToISOString(y["event-first-dt-iso"])
            ).setFullYear(2022, 0, 1) -
            +new Date(
              convertQcDateStringToISOString(x["event-first-dt-iso"])
            ).setFullYear(2022, 0, 1)
        )
      );
  };

  useEffect(() => {
    const abortController = new AbortController();

    setRecords(resultList.items);

    return () => {
      abortController.abort();
    }
  }, [])  

  return (
    <>
      <div className="intro-x">
        <Header />
      </div>
      <div className="px-4 pb-20">

        {/* Search container component */}
        <SearchEquipment
          loading={loading}
          onSearch={(query) => {
            setLoading(true);
            queryEvent(query, (items) => {
              setLoading(true);
              if (items && items === "error") setLoading(false);
              else if (items) {
                setRecords(items);
                setLoading(false);
              }
            });
          }}
        />

        {/* List Items */}
        <div
          className="flex flex-col items-center justify-center mb-14 mt-20 intro-y"
          style={{ zIndex: 0 }}
        >
          {records.length > 0 ? (
            <img className="w-52" src="/img/seen.png" alt="" />
          ) : (
            <img className="w-52" src="/img/seen-faded.png" alt="" />
          )}
          <span className="block mt-2 w-20 -ml-28 border-b-8 border-yellow-500" />
        </div>

        {/* Table */}
        <div className="bg-primary-clr-2 text-gray-100 py-4 px-16 intro-y">
          <span className="text-xs mr-2">QUERY:</span>
          { searchQuery.query }
          <span className="text-xs mr-2 ml-8">TIME PERIOD:</span>
          { searchQuery.timePeriod }
        </div>
        <table className="min-w-full">
          <thead className="bg-gray-50 border-separate intro-y">
            <tr>
              {tableHeader.map((rec, idx) => (
                <th
                  key={idx}
                  scope="col"
                  className="py-3 text-center text-sm text-gray-500 tracking-wider"
                >
                  {idx === 0 && (
                    <div
                      className="flex items-center justify-center cursor-pointer"
                      onClick={() => {
                        if (sort?.dateDescending) {
                          setSort({
                            ...sort,
                            dateDescending: false,
                          });
                          sortDate(1);
                        } else {
                          setSort({
                            ...sort,
                            dateDescending: true,
                          });
                          sortDate(2);
                        }
                      }}
                    >
                      <span>{rec}</span>
                      <span
                        className={`${!sort?.dateDescending && "rotate-180"}`}
                      >
                        <PlayIcon
                          className="w-3 h-3 rotate-90"
                          fillColor="blue"
                          strokeColor="blue"
                        />
                      </span>
                    </div>
                  )}
                  {idx === 1 && (
                    <div
                      className="flex items-center justify-center cursor-pointer"
                      onClick={() => {
                        if (sort?.timeDescending) {
                          setSort({
                            ...sort,
                            timeDescending: false,
                          });
                          sortTime(1);
                        } else {
                          setSort({
                            ...sort,
                            timeDescending: true,
                          });
                          sortTime(2);
                        }
                      }}
                    >
                      <span>{rec}</span>
                      <span
                        className={`${!sort?.timeDescending && "rotate-180"}`}
                      >
                        <PlayIcon
                          className="w-3 h-3 rotate-90"
                          fillColor="blue"
                          strokeColor="blue"
                        />
                      </span>
                    </div>
                  )}
                  {idx !== 0 && idx !== 1 && <span className="">{rec}</span>}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>

            {/* When container result is found */}
            {records.length > 0 && (
              <>
                {records.map((rec, idx) => (
                  <tr
                    key={idx}
                    className="bg-white text-center border-t border-gray-400 intro-y"
                  >
                    <td className="px-1 text-gray-700 text-sm">
                      {convertQcDateStringToDatetimeObject(
                        rec["event-first-dt-iso"]
                      )?.toLocaleDateString()}
                    </td>
                    <td className="px-1 whitespace-nowrap text-gray-700 text-sm">
                      {convertQcDateStringToDatetimeObject(
                        rec["event-first-dt-iso"]
                      )?.toLocaleTimeString()}
                    </td>
                    <td className="px-1 text-gray-700 text-sm">
                      {rec["container-id"] || "-"}
                      {rec["container-id-checksum-value"] != -1 ? rec["container-id-checksum-value"] : rec["container-id-calculated-checksum-value"] || ""}
                    </td>
                    <td className="px-1 text-gray-700 text-sm">
                      {rec["chassis-id"] || "-"}
                    </td>
                    <td className="px-1 text-gray-700 text-sm">
                      {rec["truck-id"] || "-"}
                    </td>
                    <td className="px-1 text-gray-700 text-sm">
                      {rec["location-name"] || "-"}
                    </td>
                    <td className="px-1 text-gray-700 text-sm">
                      {rec["direction-type"] || "-"}
                    </td>
                    <td className="px-1 text-gray-700 text-sm">
                      {rec["location-hub"]}
                    </td>
                    <td className="px-1 text-gray-700 text-sm flex justify-center">
                      <div className="py-1">
                        <img
                          src={rec["public-image-url"]}
                          alt="proof"
                          className="h-12 rounded-md"
                        />
                      </div>
                    </td>
                    <td className="px-1 text-center">
                      <Link
                        to="/in/proof-details"
                        state={{
                          eventId: rec["event-id"],
                        }}
                      >
                        <img src="/img/file.png" alt="file" className="h-6" />
                      </Link>
                    </td>
                  </tr>
                ))}
              </>
            )}

            {/* When no container result is found */}
            {records.length === 0 && (
              <tr>
                <td colSpan={10}>
                  <div className="bg-white px-64 pb-20 mb-10 rounded-bl-md rounded-br-md shadow-lg">
                    <h1 className="font-semibold txt-title-2 text-2xl pt-12 intro-y">
                      Oops! No search results found for query "
                      {searchQuery.query}" in this time period. Please
                      select another time period or try a new search.
                    </h1>
                    <div className="flex justify-center mt-4 intro-y">
                      <img
                        src="/img/confused.gif"
                        alt="oops"
                        className="w-2/6"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            )}

          </tbody>
        </table>
      </div>
    </>
  );
};

export default SearchResult;
