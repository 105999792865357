import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PasswordInput } from "../../components/PasswordInput";
import footerContent from "../../data/footerContent.json";
import { sha256 } from "js-sha256";
import { Button } from "../../components/Button";
import { AuthService } from "../../services/auth.service";
import { LogoWhite } from "../../svgs/LogoWhite";
import { EmailInput } from "../../components/EmailInput";

const Login = () => {
  const [record, setRecord] = useState();
  const [loading, setLoading] = useState(false);
  const [signedIn, setSignedIn] = useState(true);

  useEffect(() => {
    if (
      AuthService.GetLocalStorage("access-token") ||
      AuthService.GetLocalStorage("keep-logged-in") === "true"
    )
      window.location.href = "/in";
    else setSignedIn(false);
  }, []);

  return (
    <>
      {!signedIn && (
        <div className="auth-container h-screen">
          {/* Image background */}
          <div className="fixed w-full h-full auth-container bg-opacity-60" />

          {/* Overlay */}
          <div className="fixed w-full h-full bg-blue-900 bg-opacity-50 z-10" />

          <div className="absolute z-40 w-full">
            {/* Top Navigation */}
            <div className="flex items-center md:justify-end font-semibold px-10 py-5">
              {/* Logo */}
              <div className="mr-auto w-60 -intro-x">
                <LogoWhite />
              </div>
            </div>

            {/* form Container */}
            <div className="flex justify-center px-10">
              <div
                className="rounded-lg bg-white pt-12 pb-10 px-10 w-6/12"
                style={{ minWidth: "350px", maxWidth: "600px" }}
              >
                <label className="flex justify-center text-gray-500 text-2xl mt-6 mb-10 tracking-widest intro-y">
                  WELCOME BACK!
                </label>

                {/* Form content */}
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    setLoading(true);

                    AuthService.Login(
                      record?.username,
                      record?.password,
                      (error) => {
                        if (error) setLoading(false);
                        else {
                          if (record.keepLoggedIn)
                            AuthService.SetLocalStorage(
                              "keep-logged-in",
                              "true"
                            );
                          
                          AuthService.SetLocalStorage("user-email", record.username)
                          window.location.href = "/in";
                        }
                      }
                    );
                  }}
                >
                  {/* Email or User name */}
                  <div className="mt-6 intro-y">
                    <EmailInput
                      label="Email"
                      placeholder="Email or User Name"
                      onChange={(username) =>
                        setRecord({ ...record, username })
                      }
                    />
                  </div>

                  {/* Password */}
                  <div className="mt-6 intro-y">
                    <PasswordInput
                      label="Password"
                      placeholder="Password"
                      onChange={(password) =>
                        setRecord({ ...record, password: sha256(password) })
                      }
                    />
                  </div>
                  <div
                    className="flex space-between justify-center"
                    style={{ margin: "10px 0px" }}
                  >
                    {/* Remember me */}
                    <div className="flex mt-4 intro-y">
                      <input
                        type="checkbox"
                        style={{ margin: "0px 5px" }}
                        className="w-5 h-5"
                        id="rememberMe"
                        onChange={(e) => {
                          setRecord({
                            ...record,
                            keepLoggedIn: e.target.checked,
                          });
                        }}
                      />
                      <label
                        htmlFor="rememberMe"
                        className="ml-4 text-gray-500 cursor-pointer"
                        style={{ margin: "0px 5px" }}
                      >
                        Remember Me
                      </label>
                    </div>
                    {/* Forgot Password */}
                    <div className="flex mt-4 intro-y">
                      |
                      <Link
                        to=""
                        className="text-blue-800 hover:text-blue-900"
                        style={{ margin: "0px 5px" }}
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </div>

                  {/* Sign in button */}
                  <div className="flex justify-center mt-6 relative w-full intro-y">
                    <Button title="Log in" isLoading={loading} />
                  </div>
                </form>

                {/* Sign Up */}
                <div className="flex justify-center items-center p-3 rounded-lg hover:text-blue-900 hover:shadow-lg">
                  <Link
                    to="/signup"
                    className="mx-2 text-gray-500 font-bold underline"
                  >
                    Need an account?{" "}
                    <span className="font-bold uppercase text-lg underline">
                      Sign up
                    </span>
                  </Link>
                </div>
              </div>
            </div>

            {/* Footer */}
            <footer className="mt-8 px-10 pt-6 pb-12">
              <ul className="flex justify-center">
                {footerContent.map((cnt, idx) => (
                  <li key={idx} className="mx-5 intro-y">
                    <Link
                      to={cnt.path}
                      className="text-white font-bold hover:text-gray-200 uppercase"
                    >
                      {cnt.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </footer>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
