import { useEffect, useState } from 'react';
import { SearchIcon } from '../icons/searchIcon';

export const SearchInputPlain = ({
  label,
  placeholder,
  onChange,
  onSearch,
  onBlur,
  bgColor,
  error,
  hub,
}) => {
  const [hasError, setHasError] = useState();

  useEffect(() => {
    setHasError(error);
  }, [error]);

  return (
    <div>
      <label className='block mb-1 text-gray-700'>{label}</label>
      <div className='flex items-center w-full relative'>
        {!hub ? (
          <button className='absolute left-3 top-3'>
            <SearchIcon />
          </button>
        ) : (
          <button className='absolute right-3 top-3'>
            <SearchIcon />
          </button>
        )}
        <input
          placeholder={placeholder}
          className={`text-sm pl-10 border ${
            hasError
              ? 'border-red-500 hover:border-red-600 focus:outline-none focus:border-2 focus:border-red-600'
              : 'border-gray-400 hover:border-blue-900 focus:outline-none focus:border-2 focus:border-blue-900'
          } shadow-soft rounded-lg py-3 px-4 w-full ${bgColor} ${hub ? 'bg-[#F9F9F9]' : ''}`}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && onSearch) {
              e.preventDefault();
              onSearch(e.currentTarget.value);
            }
          }}
          onChange={(e) => {
            onChange(e.currentTarget.value);
          }}
          onBlur={(e) => {
            onBlur(e.currentTarget.value);
          }}
        />
      </div>
    </div>
  );
};
