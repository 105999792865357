import { useState } from "react";
import { Button } from "../Button";
import { PasswordInput } from "../PasswordInput";
import { hasSpecialCharacter } from "../../services/app.service";
import { hasNumber } from "../../services/app.service";
import { CheckIcon } from "../../icons/checkIcon";
import { EmailInput } from "../EmailInput";

export const Step1 = ({ onNextClick }) => {
  const [record, setRecord] = useState({});
  const [passwordMatched, setPasswordMatched] = useState(true);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [hasNum, setHasNum] = useState(false);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (passwordMatched) onNextClick(record);
      }}
    >
      {/* Email */}
      <div className="mt-6 intro-y">
        <EmailInput
          label="Email"
          placeholder="Email *"
          onChange={(email) => setRecord({ ...record, email })}
        />
      </div>

      {/* Password */}
      <div className="mt-6 intro-y">
        <PasswordInput
          label="Password"
          placeholder="Password *"
          onChange={(password) => {
            setRecord({ ...record, password });
            setHasSpecialChar(hasSpecialCharacter(password));
            setHasNum(hasNumber(password));
          }}
        />
      </div>
      <p className="mt-3 text-gray-500 text-sm intro-y">
        Must be 8 or more characters and contain at least 1 numer and 1 special
        character.
      </p>
      <div className="flex mt-2 intro-y">
        <span
          className={`${
            hasSpecialChar
              ? "bg-green-200 text-green-700 font-bold"
              : "bg-gray-200 text-gray-500"
          } py-1 px-4 rounded-full text-xs flex justify-center items-center`}
        >
          {hasSpecialChar && (
            <span className="mr-2">
              <CheckIcon color="green" />
            </span>
          )}
          <span>1 Special Character</span>
        </span>
        <span
          className={`${
            hasNum
              ? "bg-green-200 text-green-700 font-bold"
              : "bg-gray-200 text-gray-500"
          } py-1 px-4 rounded-full text-xs ml-4 flex justify-center items-center`}
        >
          {hasNum && (
            <span className="mr-2">
              <CheckIcon color="green" />
            </span>
          )}
          <span>1 Number</span>
        </span>
      </div>
      {/* Confirm Password */}
      <div className="mt-6 intro-y">
        <PasswordInput
          label="Confirm Password"
          placeholder="Confirm password"
          borderColor={
            record.password && !passwordMatched ? "border-red-500" : ""
          }
          onChange={(password) => {
            if (password === record?.password) setPasswordMatched(true);
            else setPasswordMatched(false);
          }}
        />
      </div>

      {/* Next button */}
      <div className="flex justify-center mt-10 relative intro-y">
        <Button title="Next" />
      </div>
    </form>
  );
};
