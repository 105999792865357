import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../../components/Header";
import { SearchEquipment } from "../../../components/SearchEquipment";

// Redux
import { useDispatch } from "react-redux";
import { setItems } from "../../../reducer-slices/resultlist.slice";

// API endpoint to search a particular container
import { queryEvent } from "../../../services/in.service";

const Search = () => {
  // To show loading indicator on button
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <div className="intro-x">
        <Header />
      </div>
      <div className="py-10 -mt-10">
        <div className="rounded-lg shadow-soft mx-16 px-10 pt-32 pb-40 bg-white mt-6 intro-y">
          <label className="flex justify-center items-center text-center text-3xl text-gray-600 intro-y">
            Hello, again!{" "}
            <span className="mx-3">
              <img src="/img/hi.png" alt="Hi" className="h-8" />
            </span>
          </label>
          <h1 className="font-bold text-5xl text-center txt-title mt-8 intro-y">
            <span className="border-b-8 border-yellow-500">Find</span> Your
            Equipment.
          </h1>

          {/* Search container component */}
          <SearchEquipment
            loading={loading}
            onSearch={(query) => {
              setLoading(true);
              queryEvent(query, (items) => {
                if (items && items !== "error") {
                  dispatch(setItems(items));
                  navigate("/in/search-result");
                }
                setLoading(false);
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Search;
