import { useEffect } from "react";
import ApexCharts from "apexcharts";
import { SelectInput } from "./SelectInput";

import series from "../data/graphSeries.json";

var portGraphSerie = series[0];

const options = {
  series: [portGraphSerie],
  chart: {
    type: "bar",
    height: 380,
  },
  xaxis: {
    type: "category",
    labels: {
      formatter: (val) => {
        return val;
      },
    },
    group: {
      style: {
        fontSize: "10px",
        fontWeight: 700,
      },
      groups: [{ title: "Days of week", cols: 7 }],
    },
  },
  title: {
    text: "Average Daily Location Traffic",
    align: "center",
    style: {
      fontSize: "15px",
      color: "#6D6D6D",
    },
  },
  tooltip: {
    x: {
      formatter: (val) => {
        return val;
      },
    },
  },
  fill: {
    colors: ["#0acae8"],
  },
  dataLabels: {
    style: {
      colors: ["#100e0e"],
    },
  },
};

const selectInputOptions = [
  { label: "Last 24 Hours", value: "24" },
  { label: "Last 15 mins", value: "15" },
];

const PageGraph = () => {
  useEffect(() => {
    const chart = new ApexCharts(document.querySelector("#chart"), options);
    chart.render();
  }, []);

  return (
    <div className="bg-white shadow-lg rounded-sm p-5 w-1/2 mt-8">
      <div className="flex items-center justify-between px-4">
        <span className="text-2xl font-bold">Total Port Traffic</span>
        <div className="">
          <SelectInput
            onSelect={() => {}}
            placeholder="Last 24 Hours"
            options={selectInputOptions}
            defVal="Last 24 Hours"
            hub
            size={120}
          />
        </div>
      </div>

      {/* Table  */}
      <div className="flex flex-col justify-end gap-y-0 mt-5 ml-10">
        <div className="graph-table">
          <div></div>
          <div className="underline text-lg">Inbound</div>
          <div className="underline text-lg">Outbound</div>
        </div>
        <div className="graph-table">
          <div className="row-header">Total Vehicle</div>
          <div className="text-lg">2,025</div>
          <div className="text-lg">1,895</div>
        </div>
        <div className="graph-table">
          <div className="row-header">Container</div>
          <div className="text-lg">2,054</div>
          <div className="text-lg">1,396</div>
        </div>
      </div>

      {/*Graph */}

      <div className="mx-20 mt-10">
        <div id="chart" className="z-0 "></div>
      </div>
    </div>
  );
};

export default PageGraph;
