export const PersonAvatar = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7197 20.6248H10.2797C7.69481 20.6277 5.21666 21.6559 3.38879 23.4838C1.56097 25.3116 0.532731 27.7899 0.529785 30.3747C0.529785 30.5622 0.629818 30.7356 0.792285 30.8294C0.954752 30.9232 1.15483 30.9231 1.31728 30.8294C1.47974 30.7356 1.57978 30.5622 1.57978 30.3747C1.58241 28.0681 2.49979 25.8568 4.13085 24.2258C5.76179 22.5947 7.97326 21.6773 10.2798 21.6747H18.7197C21.0263 21.6773 23.2376 22.5947 24.8686 24.2258C26.4997 25.8567 27.4171 28.0682 27.4197 30.3747C27.4197 30.5622 27.5197 30.7356 27.6822 30.8294C27.8447 30.9232 28.0447 30.9231 28.2072 30.8294C28.3697 30.7356 28.4697 30.5622 28.4697 30.3747C28.4667 27.7898 27.4385 25.3116 25.6107 23.4838C23.7829 21.6559 21.3045 20.6277 18.7198 20.6248H18.7197Z"
        fill="#6D6D6D"
      />
      <path
        d="M6.26416 8.33573C6.26416 10.5201 7.13187 12.6148 8.67633 14.1594C10.2209 15.704 12.3157 16.5716 14.5 16.5716C16.6843 16.5716 18.7791 15.704 20.3237 14.1594C21.8682 12.615 22.7359 10.52 22.7359 8.33573C22.7359 6.15144 21.8682 4.05669 20.3237 2.51202C18.7792 0.967562 16.6843 0.0998535 14.5 0.0998535C12.3164 0.102132 10.223 0.970624 8.67896 2.51465C7.13487 4.05867 6.26644 6.15202 6.26416 8.33573ZM21.6857 8.33573C21.6857 10.2416 20.9287 12.0694 19.5811 13.4169C18.2335 14.7644 16.4057 15.5215 14.5 15.5215C12.5942 15.5215 10.7663 14.7644 9.41883 13.4169C8.07124 12.0693 7.31416 10.2415 7.31416 8.33573C7.31416 6.42998 8.07124 4.60211 9.41883 3.25461C10.7664 1.90702 12.5942 1.14994 14.5 1.14994C16.4051 1.15199 18.2318 1.90976 19.5787 3.25688C20.926 4.60403 21.6836 6.43051 21.6857 8.33567L21.6857 8.33573Z"
        fill="#6D6D6D"
      />
    </svg>
  );
};
