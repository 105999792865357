import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import footerContent from "../../data/footerContent.json";
import { CheckIcon } from "../../icons/checkIcon";
import { ArrowBackIcon } from "../../icons/arrowBackIcon";
import { Step1 } from "../../components/signup/Step1";
import { Step2 } from "../../components/signup/Step2";
import { LogoWhite } from "../../svgs/LogoWhite";
import { AuthService } from "../../services/auth.service";
import { sha256 } from "js-sha256";

const Signup = () => {
  const [record, setRecord] = useState({});
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [signedIn, setSignedIn] = useState(true);

  useEffect(() => {
    if (
      AuthService.GetLocalStorage("access-token") ||
      AuthService.GetLocalStorage("keep-logged-in") === "true"
    )
      window.location.href = "/in";
    else setSignedIn(false);
  }, []);

  return (
    <>
      {!signedIn && (
        <div className="h-screen">
          {/* Image background */}
          <div className="fixed w-full h-full auth-container" />

          {/* Overlay */}
          <div className="fixed w-full h-full bg-blue-900 bg-opacity-50 z-10" />

          <div className="absolute z-40 w-full">
            {/* Top Navigation */}
            <div className="flex items-center md:justify-end font-semibold px-10 py-5">
              {/* Logo */}
              <div className="mr-auto w-60 -intro-x">
                <LogoWhite />
              </div>
            </div>

            {/* form Container */}
            <div className="flex justify-center px-10">
              <div
                className="rounded-lg bg-white pt-12 pb-10 px-10 w-6/12"
                style={{ minWidth: "350px", maxWidth: "600px" }}
              >
                <label className="flex justify-center text-gray-500 text-2xl mt-6 mb-10 tracking-widest intro-y">
                  SIGN UP
                </label>
                {/* Sign up / Login nav */}
                <div className="flex uppercase intro-y"></div>
                <div className="flex justify-center items-center intro-y">
                  {step === 1 && (
                    <span className="flex w-10 h-10 bg-primary-clr rounded-full justify-center items-center">
                      <span className="block text-white font-bold">1</span>
                    </span>
                  )}
                  {step === 2 && (
                    <span className="flex w-10 h-10 bg-green-500 rounded-full justify-center items-center">
                      <span className="block">
                        <CheckIcon color="white" />
                      </span>
                    </span>
                  )}
                  <span className="mx-5 my-5 text-gray-300">
                    ---------------
                  </span>

                  <span
                    className={`flex w-10 h-10 ${
                      step === 1
                        ? "bg-gray-200 text-gray-400"
                        : "bg-primary-clr text-white"
                    } rounded-full justify-center items-center`}
                  >
                    <span className="block">2</span>
                  </span>
                </div>

                {/* Signup step 1 */}
                {step === 1 && (
                  <Step1
                    onNextClick={(_record) => {
                      setRecord({ ...record, ..._record });
                      setStep(2);
                    }}
                  />
                )}

                {/* Signup step 2 */}
                {step === 2 && (
                  <Step2
                    loading={loading}
                    onSubmit={(_record) => {
                      setLoading(true);
                      // Call Signup endpoint mergin Previously saved Record with incoming Record
                      AuthService.Signup(
                        {
                          ...record,
                          ..._record,
                          username: record?.email,
                          password: sha256(record?.password),
                          code: "QCALPHA",
                        },
                        (error) => {
                          if (error) setLoading(false);
                          else window.location.href = "/login";
                        }
                      );
                    }}
                  />
                )}

                {step === 1 && (
                  <div className="flex justify-center items-center p-3 rounded-lg hover:text-blue-900 hover:shadow-lg">
                    <Link
                      to="/login"
                      className="mx-2 text-gray-500 font-bold underline"
                    >
                      Have an account?{" "}
                      <span className="font-bold uppercase text-lg underline">
                        Login
                      </span>
                    </Link>
                  </div>
                )}

                {/* Back button */}
                {step === 2 && (
                  <div
                    className="flex justify-center my-8 intro-y"
                    style={{ zIndex: 0 }}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        if (step === 2) setStep(1);
                      }}
                      className="flex justify-center items-center p-3 rounded-lg hover:text-blue-900 hover:shadow-lg"
                    >
                      {step === 2 && <ArrowBackIcon color="#666" />}
                      <span className="mx-2 text-gray-500 font-bold underline">
                        BACK
                      </span>
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* Footer */}
            <footer className="mt-8 px-10 py-6">
              <ul className="flex justify-center">
                {footerContent.map((cnt, idx) => (
                  <li key={idx} className="mx-5 intro-y">
                    <Link
                      to={cnt.path}
                      className="text-white hover:text-gray-200 uppercase font-bold"
                    >
                      {cnt.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </footer>
          </div>
        </div>
      )}
    </>
  );
};

export default Signup;
