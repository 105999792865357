type Props = {
  width?: string;
  height?: string;
};

export const Close = ({ width, height }: Props) => {
    return (

    <svg
        width={width}
        height={height}
        viewBox="0 0 10 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
            <g
                clip-path="url(#clip0_5170_2181)"
            >
                <path
                    d="M9.1875 1.47503C8.9375 1.22097 8.5625 1.22097 8.3125 1.47503L5 4.8413L1.6875 1.47503C1.4375 1.22097 1.0625 1.22097 0.8125 1.47503C0.5625 1.72909 0.5625 2.11017 0.8125 2.36423L4.125 5.73051L0.8125 9.09678C0.5625 9.35084 0.5625 9.73193 0.8125 9.98599C0.9375 10.113 1.0625 10.1765 1.25 10.1765C1.4375 10.1765 1.5625 10.113 1.6875 9.98599L5 6.61971L8.3125 9.98599C8.4375 10.113 8.625 10.1765 8.75 10.1765C8.875 10.1765 9.0625 10.113 9.1875 9.98599C9.4375 9.73193 9.4375 9.35084 9.1875 9.09678L5.875 5.73051L9.1875 2.36423C9.4375 2.11017 9.4375 1.72909 9.1875 1.47503Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath
                    id="clip0_5170_2181"
                >
                    <rect
                        width="10"
                        height="10.1623"
                        fill="white"
                        transform="translate(0 0.649353)"
                    />
                </clipPath>
            </defs>
    </svg>
  );
};

