import { useEffect, useState } from "react";
import { Button } from "../Button";
import { SelectInput } from "../SelectInput";
import { TextInput } from "../TextInput";

export const Step2 = ({ onSubmit, loading }) => {
  const [record, setRecord] = useState();
  const [isLoading, setIsLoading] = useState();

  const depts = [
    { label: "Department 1", value: "department 1" },
    { label: "Department 2", value: "department 2" },
    { label: "Department 3", value: "department 3" },
    { label: "Department 4", value: "department 4" },
  ];

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(record);
      }}
    >
      {/* First Name */}
      <div className="mt-6 intro-y">
        <TextInput
          label="First Name"
          stared={true}
          placeholder="First Name"
          onChange={(firstname) => setRecord({ ...record, firstname })}
        />
      </div>

      {/* Last name */}
      <div className="mt-6 intro-y">
        <TextInput
          label="Last Name"
          stared={true}
          placeholder="Last Name"
          onChange={(lastname) => setRecord({ ...record, lastname })}
        />
      </div>

      {/* Company */}
      <div className="mt-6 intro-y">
        <TextInput
          label="Company"
          stared={true}
          placeholder="Company"
          onChange={(company) => setRecord({ ...record, company })}
        />
      </div>

      <div className="mt-6 flex intro-y">
        {/* Zip code */}
        <div className="mr-2">
          <TextInput
            label="Zip Code"
            stared={true}
            placeholder="Zip Code"
            onChange={(zipCode) =>
              setRecord({ ...record, ["zip-code"]: zipCode })
            }
          />
        </div>
        {/* Department */}
        <div className="ml-2 mt-1">
          <SelectInput
            label="Department"
            required={false}
            placeholder="Select Department *"
            onSelect={(department) =>
              setRecord({ ...record, department: department?.value })
            }
            options={depts}
          />
        </div>
      </div>

      {/* Sign up button */}
      <div className="flex justify-center mt-20 relative intro-y">
        <Button title="Sign up" isLoading={isLoading} />
      </div>
    </form>
  );
};
