import { PersonAvatar } from "../svgs/PersonAvatar";

export const Header = () => {
  return (
    <header className="flex justify-end items-center w-full py-3 z-50">
      {/* Customer name and Image */}
      <div className="flex flex-col items-center">
        <div className="w-6 h-6">
          <PersonAvatar />
        </div>
        <h1 className="text-gray-600 text-xs mt-2">ACCOUNT</h1>
      </div>
    </header>
  );
};
