import { calcChecksum } from "./checksum.service";

// Checks if a string contains a special character
export const hasSpecialCharacter = (str) => {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
};

// Checks if a string contains a Number
export const hasNumber = (str) => {
  const regex = /\d/g;
  return regex.test(str);
};

// Checks if a string is a valid Container Number
export const validContainerNumber = (containerId) => {
  const validNumber = /^[a-zA-Z]{3}u\d{7}/i.test(containerId);
  if (validNumber) {
    const lastDigit = calcChecksum(containerId.slice(0, 10));
    if (lastDigit.toString() === Array.from(containerId)[10]) return true;
  }
  return false;
};

export const validChassisNumber = (chassisId) => {
  const validNumber = /^[a-zA-Z]{3}z\d{6}/i.test(chassisId);
  return validNumber;
}

// Checks if a string is a valid Email
export const validEmail = (email) => {
  let regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-z]+)$/;
  return regex.test(email);
};

// ### Date and time Converters Start here

export const convertQcDateStringToISOString = (qcDateString = "") => {
  let dateString = qcDateString?.replace("-UTC", "Z")?.split("T");
  dateString[1] = dateString[1]?.replace("-", ":")?.replace("-", ":");
  return dateString[0] + "T" + dateString[1];
};

export const convertISOStringToQcDateString = (
  datetimeISOString = ""
) => {
  return datetimeISOString
    ?.replace(":", "-")
    ?.replace(":", "-")
    ?.replace("Z", "-UTC");
};

export const convertQcDateStringToDatetimeObject = (
  qcDateString = ""
) => {
  qcDateString = convertQcDateStringToISOString(qcDateString);
  return new Date(qcDateString);
};

export const qcUTCDatetimeString = (isoDatetimeString = "") => {
  if (!isoDatetimeString) {
    const isoDate = new Date().toISOString();
    return convertISOStringToQcDateString(isoDate);
  }

  return convertISOStringToQcDateString(isoDatetimeString);
};

export const qcDatetimeDifference = (
  qcDate1 = "",
  qcDate2 = ""
) => {
  const date1 = convertQcDateStringToDatetimeObject(qcDate1);
  const date2 = convertQcDateStringToDatetimeObject(qcDate2);
  return Math.abs(date1.valueOf() - date2.valueOf());
};

export const getLocaleDatetimeString = () => {
  let months = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let dt_arr = new Date().toString().split(" ");

  const year = dt_arr[3];
  const month = months.indexOf(dt_arr[1]);
  const day = dt_arr[2];

  const time = dt_arr[4].replaceAll(":", "-");

  return `${year}-${month}-${day}T${time}`;
};

// ### Date and time Converters end here

export const createCountKey = (
  keyType = "",
  datetimeISOString = ""
) => {
  const key = keyType.toUpperCase() + "_" + datetimeISOString.substring(0, 13);
  return key;
};
