import { createSlice } from "@reduxjs/toolkit";

export const resultListSlice = createSlice({
    name: "results",
    initialState: {
        items: []
    },
    reducers: {
        setItems: (state, actions) => {
            state.items = actions.payload;
        },
        reset: (state) => {
            state.items = [];
        }
    }
});

export const { setItems, reset } = resultListSlice.actions;

export default resultListSlice.reducer;