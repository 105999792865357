import { useEffect, useState } from "react";

export const TextInput = ({
  label,
  placeholder,
  type = "text",
  bgColor,
  onChange,
  required = true,
  stared,
  disabled,
  defVal,
  onEnter,
}) => {
  const [defValue, SetDefValue] = useState(defVal || "");

  useEffect(() => {
    SetDefValue(defVal || "");
  }, [defVal]);

  return (
    <div>
      <label className="block mb-1 text-gray-700">
        {label} {stared && <span className="mx-1 text-lg text-red-600">*</span>}
      </label>
      <input
        className={`p-3 block w-full text-sm rounded-md text-gray-600 border border-gray-400 hover:border-blue-900 focus:outline-none focus:border-2 focus:border-blue-900 ${bgColor}`}
        placeholder={placeholder}
        required={required}
        type={type}
        value={defValue || ""}
        disabled={disabled}
        onKeyPress={(e) => {
          if (e.key === "Enter" && onEnter) {
            e.preventDefault();
            onEnter();
          }
        }}
        onChange={(e) => {
          SetDefValue(e.currentTarget.value);
          onChange(e.currentTarget.value);
        }}
      />
    </div>
  );
};
