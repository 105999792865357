export const Faq = ({ width, height }) => {
    return (

    <svg
        width={width}
        height={height}
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
    
            <path
                d="M17.6052 34.335C8.2602 34.335 0.700195 26.74 0.700195 17.43C0.700195 8.12002 8.2602 0.525024 17.6052 0.525024C26.9502 0.525024 34.5102 8.12002 34.5102 17.43C34.5101 26.74 26.9152 34.335 17.6052 34.335ZM17.6052 2.59002C9.4152 2.59002 2.8002 9.24002 2.8002 17.43C2.8002 25.585 9.4502 32.235 17.6052 32.235C25.7602 32.235 32.4102 25.585 32.4102 17.43C32.4101 9.24002 25.7602 2.59002 17.6052 2.59002Z"
                fill="#9B9B9B" />
            <path
                d="M17.7797 8.43506C14.5947 8.43506 11.9697 11.0251 11.9697 14.2451C11.9697 14.7701 12.3897 15.19 12.9147 15.19C13.4397 15.19 13.8596 14.7701 13.8596 14.2451C13.8596 12.0751 15.6096 10.3251 17.7796 10.3251C19.9497 10.3251 21.6996 12.0751 21.6996 14.2451C21.6996 16.415 19.9496 18.1651 17.7796 18.1651C17.2546 18.1651 16.8347 18.585 16.8347 19.11V21.945C16.8347 22.47 17.2546 22.8899 17.7796 22.8899C18.3046 22.8899 18.7246 22.47 18.7246 21.945V19.9501C21.4896 19.4951 23.5896 17.1151 23.5896 14.2101C23.5896 11.0251 20.9995 8.43512 17.7796 8.43512L17.7797 8.43506Z"
                fill="#9B9B9B" />
            <path
                d="M19.1102 25.76C19.1102 26.4945 18.5148 27.0901 17.7803 27.0901C17.0456 27.0901 16.4502 26.4945 16.4502 25.76C16.4502 25.0255 17.0456 24.4301 17.7803 24.4301C18.5148 24.4301 19.1102 25.0255 19.1102 25.76Z"
                fill="#9B9B9B" />
    </svg>
  );
};
