import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { sha256 } from "js-sha256";

import {
  getPDF,
  sendEmail,
} from "../../../services/in.service";
import { SearchEquipment } from "../../../components/SearchEquipment";
import { convertQcDateStringToDatetimeObject } from "../../../services/app.service";
import { Header } from "../../../components/Header";
import { ProofModal } from "../../../components/ProofModal";
import { useNavigate } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setItems } from '../../../reducer-slices/resultlist.slice';
import { setEventData } from "../../../reducer-slices/pdf.slice";

// API endpoint to search a particular container
import { queryEvent } from "../../../services/in.service";
import EquipmentTable from "../../../components/EquipmentTable";

const ProofDetails = () => {
  const [reportId, setReportId] = useState("");

  const [reportCreated, setReportCreated] = useState(null);
  const [timezone, setTimezone] = useState(null);

  const [pdfLoading, setPdfLoading] = useState();
  const [loading, setLoading] = useState(false);

  const [eventRecord, setEventRecord] = useState(null);
  const [loadingEvent, setLoadingEvent] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  // redux getter and setter
  const dispatch = useDispatch();
  const pdfData = useSelector(state => state.pdf);

  useEffect(() => {
    // Generate the report here so it's consistent in the modal view
    setReportId(sha256(new Date().toUTCString()).substr(0, 12));
    setReportCreated(new Date());

    // set timezone
    const tz = Number(new Date().getTimezoneOffset() / 60);
    
    if (tz < 0) {
      setTimezone(`+${tz}`);
    } else if (tz > 0) {
      setTimezone(`-${tz}`);
    } else if (tz === 0) {
      setTimezone("");
    }
  }, []);

  useEffect(() => {
    const abortController = new AbortController();

    const baseUrl = "https://1oc88j93je.execute-api.us-west-2.amazonaws.com";
    const eventId = location.state.eventId;

    const fetchEventData = async (baseUrl, eventId) => {
      await fetch(`${baseUrl}/event/${eventId}`)
        .then(data => data.json())
        .then(json => {
          setEventRecord(json["Items"][0]);

          // Some values must be reformatted as such
          const seenDate = convertQcDateStringToDatetimeObject(
            eventRecord["event-first-dt-iso"]
          )?.toLocaleDateString("en-US");
          const seenTime = convertQcDateStringToDatetimeObject(
            eventRecord["event-first-dt-iso"]
          )?.toLocaleTimeString();


          dispatch(setEventData({
            ...eventRecord,
            "container-image-url": eventRecord["public-image-url"],
            "seen-date": seenDate,
            "seen-time": seenTime,
            "location": eventRecord["location-name"]
          }));

          setLoadingEvent(false);
        });
    }

    setLoadingEvent(true);
    fetchEventData(baseUrl, eventId);

    return () => {
      abortController.abort();
    };
  }, [eventRecord, loading]);


  const fourZeroFour = () => {
    return (
      <div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
          {loadingEvent ?
            <div className="bg-black w-5 h-5 rounded-full mr-4 animate-ping" style={{ margin: "10px 25px" }} />
            : "Invalid Event"}
        </div>
      </div>
    )
  }

  const equiptmentDetails = (containerId, chassisId, truckId) => {
    return (
      <div style={{ display: "flex", flexDirection: window.innerWidth < 1024 ? "column" : "row", justifyContent: "center", marginTop: "10px" }}>
        <EquipmentTable EquipmentType="Container" EquipmentId={containerId} />
        <EquipmentTable EquipmentType="Chassis" EquipmentId={chassisId} />
        <EquipmentTable EquipmentType="Truck" EquipmentId={truckId} />
      </div>
    )
  }

  const eventDetails = () => {
    return (
      <div className="rounded-lg bg-white block px-16 py-10 shadow-lg" style={{ maxWidth: 1024, margin: "0 auto" }}>

        {/* Event Info */}
        <div className="flex" style={{ flexDirection: window.innerWidth < 800 ? "column" : "row", justifyContent: window.width < 800 ? "center" : "space-between" }}>
          {/* Title and Logo */}
          {/* Title */}
          <h1
            className="text-10xl font-semibold txt-title intro-x"
            style={{ zIndex: 0, fontSize: "2.5rem", justifyContent: "center" }}
          >
            Here's Your{" "}
            <span className="border-b-8 border-yellow-400">Proof!</span>
          </h1>

          {/* Logo section */}
          <div className="flex-col intro-x">
            <img src="/img/logo.png" alt="logo" />
            <div className="flex ml-16 -mt-6 p-2">
              <label className="text-3xl p-4 ml-6 text-gray-700">Verified</label>
              <img src="/img/verified.gif" className="w-16 h-16 -ml-3" />
            </div>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>

          <div className="intro-y">
            {/* Image */}
            <img
              src={eventRecord["public-image-url"] || "/img/proof.png"}
              alt="Proof-img"
              className="rounded-sm"
              style={{ width: "100%", height: "100%" }}
            />
          </div>

          {/* Table */}
          <div className="flex mt-10" style={{ width: "100%", justifyContent: "space-between" }}>
            <table className="intro-y">
              <tbody>
                <tr className="block py-2">
                  <td className="font-semibold text-gray-800 w-36">
                    Seen Date
                  </td>
                  <td className="text-gray-700">
                    {convertQcDateStringToDatetimeObject(
                      eventRecord["event-first-dt-iso"]
                    )?.toLocaleDateString("en-US") || "-"}
                  </td>
                </tr>
                <tr className="block py-2">
                  <td className="font-semibold text-gray-800 w-36">
                    Seen Time
                  </td>
                  <td className="text-gray-700">
                    {convertQcDateStringToDatetimeObject(
                      eventRecord["event-first-dt-iso"]
                    )?.toLocaleTimeString() + " UTC" + timezone || "-"}
                  </td>
                </tr>
                <tr className="block py-2">
                  <td className="font-semibold text-gray-800 w-36">
                    Direction
                  </td>
                  <td className="text-gray-700">
                    {eventRecord["direction-type"] || "-"}
                  </td>
                </tr>
                <tr className="block py-2">
                  <td className="font-semibold text-gray-800 w-36">
                    Location
                  </td>
                  <td className="text-gray-700 pr-2">
                    {eventRecord["location-name"] || "-"}
                  </td>
                </tr>
                <tr className="block py-2">
                  <td className="font-semibold text-gray-800 w-36">
                    Hub
                  </td>
                  <td className="text-gray-700">
                    {eventRecord["location-hub"] || "-"}
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ minWidth: 60, maxWidth: 60 }}>
              {/* Buttons */}
              <div className="flex-col justify-content-center intro-x" style={{ width: "100%", alignItems: "flex-end" }}>

                {/* Share button */}
                <img
                  src="/img/share.png"
                  alt="share"
                  className="cursor-pointer ml-2 mb-2"
                  style={{ width: 60, height: 65 }}
                  onClick={() => {
                    setShowModal(true);
                  }}
                />

                {/* Download button */}
                {!pdfLoading && (
                  <img
                    src="/img/download.png"
                    alt="download"
                    className="cursor-pointer ml-1"
                    style={{ width: 60, height: 65 }}
                    onClick={() => {
                      setPdfLoading(true);
                      getPDF(pdfData, (data) => {
                        if (data !== "error") {
                          window.open(data);
                          setPdfLoading(false);
                          return false;
                        }
                        setPdfLoading(false);
                      });
                    }}
                  />
                )}
                {pdfLoading && (
                  <img src="/img/spinner.gif" className="w-16 mt-3" />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Tables */}
        {equiptmentDetails(eventRecord["container-id"], eventRecord["chassis-id"], eventRecord["truck-id"])}

        {/* Report generated at: */}
        <div className="flex justify-start">
          <div className="flex flex-col items-start text-sm mt-10 intro-y">
            <label><span style={{ fontWeight: "bold" }}>Report created at </span>{reportCreated?.toLocaleDateString("en-US") || "-"} {reportCreated?.toLocaleTimeString() + " UTC" + timezone || " "}</label>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>

      <div>
        <Header />
      </div>

      <div className="px-4 relative -mt-10 mb-20 z-0">
        <SearchEquipment
          loading={loading}
          onSearch={(query) => {
            setLoading(true);
            queryEvent(query, (items) => {
              if (items && items === "error") setLoading(false);
              else if (items) {
                setLoading(false);
                dispatch(setItems(items));
                navigate("/in/search-result");
              }
            });
            setLoading(false);
          }}
        />
      </div>
      {/* When event is invalid or loading */}
      {!eventRecord && fourZeroFour()}

      {/* Top event details and buttons alongside event tables */}
      {eventRecord && eventDetails()}

      {/* Modal */}
      {showModal && <ProofModal SendEmail={sendEmail} showModal={showModal} setShowModal={setShowModal} PDFRecord={pdfData} ReportId={reportId} />}

    </div>
  )

};

export default ProofDetails;
